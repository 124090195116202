.root{
  @apply
  text-xs capitalize py-2 px-4 w-1/2 shadow-md font-primary border-none
}
.inicio{
  @apply rounded-l-full
}
.fin{
  @apply rounded-r-full
}
.centro{
  @apply rounded-none
}

.rootBase{
  @apply bg-white hover:bg-neutral-90
}
.rootActive{
  @apply bg-uv-secondary-0 text-white hover:bg-uv-secondary-10
}

.disabled{
  @apply bg-white
}