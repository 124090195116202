.error {
  @apply border-error-dark
  hover:border-error-dark
  focus:border-error-dark;
}
.feedback-container- {
  @apply bg-white;
}
.feedback-container-info {
  @apply bg-white;
}
.feedback-container-error {
  @apply bg-error-dark;
}
.feedback-container-success {
  @apply bg-success-light;
}
.feedback-container-warning {
  @apply bg-warning-light;
}

.success {
  @apply border-success-dark
  hover:border-success-dark
  focus:border-success-dark;
}

.feedbackerror {
  @apply text-white;
}
.feedbacksuccess {
  @apply text-success-dark;
}
.feedbackinfo {
  @apply text-info-dark;
}
.feedbackwarning {
  @apply text-warning-dark;
}
