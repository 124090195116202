$primary_font_family: 'Open Sans', sans-serif;
$titulo_principal: #5e5f78;
$color_botones: #47a7b7;
$color_botones_azul: #3f4d71;
$color_gris_claro: #ddd;
$color_red: #b03564;

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #e2e8ef;
  height: 100%;
  font-size: 13.5px;
  font-family: $primary_font_family;
  padding-right:0px !important;
  overflow: inherit !important;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.MuiTableCell-stickyHeader {
  font-weight: bold;
}

.MuiChip-root {
  max-width: 100%;
}

.address-icon svg {
  float: left;
  margin-right: 8px;
}

.logo-thumbnail {
  min-height: 100px;
  width: 100%;
  padding-top: 30px;

  .big-logo {
    width: 90px;
    height: 90px;
    margin: auto;
    border: 1px solid $color_gris_claro;
  }

  .biggest-logo {
    width: 140px;
    height: 140px;
    margin: auto;
    border: 1px solid $color_gris_claro;
  }
}

[type='file'] {
  height: 0;
  overflow: hidden;
  width: 0;
  position: absolute;
}

[type='file'] + label .file-btn {
  font-size: 14px;
  background: $color_botones;
  border: none;
  color: #fff;
  cursor: pointer;
  display: block;
  font-weight: 600;
  outline: none;
  padding: 0.6rem 1rem;
  position: relative;
  transition: all 0.3s;
  float: right;

  &:hover {
    background-color: black;
  }
}

.MuiFormHelperText-root.Mui-error {
  font-size: 12px;
  letter-spacing: 0px;
  padding-left: 0px;
  margin-left: 0px;
}

.MuiTreeItem-content .MuiCheckbox-root {
  float: left;
  padding: 0 10px;
  margin-top: -4px;
}
.MuiTreeItem-content .MuiTreeItem-label {
  padding: 12px;
  font-size: 13px;
  line-height: 16px;
}

.industriacircular{
  .MuiInputLabel-root.Mui-focused{
    color:$color_botones;
  }

  .MuiTextField-root .Mui-focused .MuiOutlinedInput-notchedOutline{
    border:0px;
    outline: none;
  }

  .MuiFormGroup-root{
    display:block;
    flex-direction: row;

    .industriacircular label{
      display:block;
    }
  }
}

.MuiTextField-root label{
  transform:translate(14px, 15px) scale(1);
}

.MuiDrawer-paperAnchorLeft {
  min-width: 280px;
}

.loginPopUp {
  top: 55px !important;

  .login-body {
    padding: 10px 20px;
  }
}

.leaflet-popup-content {
  margin: 0px;
}
.leaflet-popup-content-wrapper {
  border-radius: 3px;
  box-shadow: 0 3px 60px rgba(0, 0, 0, 0.2);
}

.MuiAccordion-root {
  .MuiAccordionActions-root {
    .color-delete {
      color: $color_red;
    }
  }
}

.link-detail {
  .MuiChip-labelSmall {
    color: $color_botones_azul;
  }
  .link-description {
    display: block;
    margin-top: 5px;
    font-size: 13px;
    color: $titulo_principal;
  }
}

.leaflet-control-attribution {
  display: none;
}
.leaflet-control-container {
  .leaflet-left {
    bottom: 10px;
    top: inherit;
  }
}

.indice-data {
  display:block;
  text-align:center;
  .medicion {
    font-size: 14px;
    color: $color_botones_azul;
    opacity: 0.8;
    padding: 10px 10px;
    font-weight: 400;
  }
}

@media only screen and (max-width: 1920px) {
  .indice-data{display:block;text-align:center;}
  .indice-data .medicion{padding:4px;}
}

@media only screen and (max-width: 959px) {
  //Editar Sucursales /empresa/editar
  .form .sucursal h4,
  .form .sucursal p{font-size:13px;}
}

//SM
@media only screen and (max-width: 599px) {
  .form h2{font-size:14px;}
  .MuiSelect-select.MuiSelect-select{font-size:12px;}
  .leaflet-popup-content{width:270px !important;}  
}