@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  
  .scrollbar-uv::-webkit-scrollbar {
    height: theme('height.6');
    background-color: rgba(255, 255, 255, 0);
  }
  .scrollbar-uv::-webkit-scrollbar-track,
  .scrollbar-uv::-webkit-scrollbar-thumb {
    border: 4px solid rgba(255, 255, 255, 0);
    background-clip: padding-box;
    border-radius: theme('height.screen');
  }
  .scrollbar-uv::-webkit-scrollbar-track {
    background-color: #edf2f7;
  }
  .scrollbar-uv::-webkit-scrollbar-thumb {
    background-color: theme('colors.uv-primary.80')
  }
  .scrollbar-uv::-webkit-scrollbar-thumb:hover {
    background-color: theme('colors.uv-primary.10');
    border-radius: theme('height.screen');
    border: 4px solid rgba(255, 255, 255, 0);
  }

  .diagonal-shape{
    clip-path: polygon(13% 0, 100% 0%, 100% 100%, 0% 100%);
  }
  
}
