.header{
  @apply bg-uv-primary-0 relative
}

.content{
  @apply flex-grow flex w-full m-auto justify-center
}

.root{
  @apply pt-14 h-full m-auto sm:pt-20
}